var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"appRef",style:({
    backgroundImage: 'url(' + _vm.bgUrl + ')',
    width: _vm.size === 'small' ? '1920px' : '5120px',
    height: '1080px',
  }),attrs:{"id":"index"}},[(_vm.$store.getters.loading)?_c('dv-loading',[_vm._v("Loading...")]):_c('div',{style:({ position: 'relative' })},[(_vm.size === 'small')?[_c('Header'),_c('Modal2',{style:({
          position: 'absolute',
          top: '251px',
          left: '589px',
        }),attrs:{"size":_vm.size,"title":_vm.$store.state.modalContent.title,"code":_vm.$store.state.modalContent.code,"event":_vm.$store.state.modalContent.event,"time":_vm.$store.state.modalContent.time},model:{value:(_vm.$store.state.modalShow),callback:function ($$v) {_vm.$set(_vm.$store.state, "modalShow", $$v)},expression:"$store.state.modalShow"}}),_c('Modal',{style:({
          position: 'absolute',
          top: '251px',
          left: '589px',
        }),attrs:{"size":_vm.size,"title":(_vm.alarmArr[0] || {}).liftName,"code":(_vm.alarmArr[0] || {}).liftCode,"event":(_vm.alarmArr[0] || {}).faultContent,"time":(_vm.alarmArr[0] || {}).faultTime},model:{value:(_vm.modalShow1),callback:function ($$v) {_vm.modalShow1=$$v},expression:"modalShow1"}}),_c('Modal',{style:({
          position: 'absolute',
          top: '227px', // -24
          left: '630px', // +41
        }),attrs:{"size":_vm.size,"title":(_vm.alarmArr[1] || {}).liftName,"code":(_vm.alarmArr[1] || {}).liftCode,"event":(_vm.alarmArr[1] || {}).faultContent,"time":(_vm.alarmArr[1] || {}).faultTime},model:{value:(_vm.modalShow2),callback:function ($$v) {_vm.modalShow2=$$v},expression:"modalShow2"}}),_c('Modal',{style:({
          position: 'absolute',
          top: '203px', // -24
          left: '671px', // +41
        }),attrs:{"size":_vm.size,"title":(_vm.alarmArr[2] || {}).liftName,"code":(_vm.alarmArr[2] || {}).liftCode,"event":(_vm.alarmArr[2] || {}).faultContent,"time":(_vm.alarmArr[2] || {}).faultTime},model:{value:(_vm.modalShow3),callback:function ($$v) {_vm.modalShow3=$$v},expression:"modalShow3"}}),_c('Modal',{style:({
          position: 'absolute',
          top: '181px', // -24
          left: '712px', // +41
        }),attrs:{"size":_vm.size,"title":(_vm.alarmArr[3] || {}).liftName,"code":(_vm.alarmArr[3] || {}).liftCode,"event":(_vm.alarmArr[3] || {}).faultContent,"time":(_vm.alarmArr[3] || {}).faultTime},model:{value:(_vm.modalShow4),callback:function ($$v) {_vm.modalShow4=$$v},expression:"modalShow4"}}),_c('Modal',{style:({
          position: 'absolute',
          top: '157px', // -24
          left: '752px', // +41
        }),attrs:{"size":_vm.size,"title":(_vm.alarmArr[4] || {}).liftName,"code":(_vm.alarmArr[4] || {}).liftCode,"event":(_vm.alarmArr[4] || {}).faultContent,"time":(_vm.alarmArr[4] || {}).faultTime},model:{value:(_vm.modalShow5),callback:function ($$v) {_vm.modalShow5=$$v},expression:"modalShow5"}}),_c('Overview',{style:({
          position: 'absolute',
          top: '90px',
          left: '30px',
        }),attrs:{"width":"490px","height":"202px"}}),_c('OverviewBar',{style:({
          position: 'absolute',
          top: '312px',
          left: '30px',
        }),attrs:{"size":_vm.size}}),_c('RuningTime',{style:({
          position: 'absolute',
          top: '600px',
          left: '30px',
        }),attrs:{"size":_vm.size}}),_vm._v(" /> "),_c('EfficiencyRanking',{style:({
          position: 'absolute',
          top: '840px',
          left: '30px',
        }),attrs:{"size":_vm.size}}),_c('EnergyConsumptionRanking',{style:({
          position: 'absolute',
          top: '840px',
          left: '540px',
          zIndex: 10,
        }),attrs:{"size":_vm.size}}),_c('EnergyConsumptionTrend',{style:({ position: 'absolute', top: '840px', left: '979px' }),attrs:{"size":_vm.size}}),_c('GuzhangRate',{style:({ position: 'absolute', top: '840px', right: '30px' }),attrs:{"size":_vm.size}}),_c('EventTrend',{style:({ position: 'absolute', top: '580px', right: '30px' }),attrs:{"size":_vm.size}}),_c('Top',{style:({ position: 'absolute', top: '242px', right: '30px' }),attrs:{"size":_vm.size}}),_c('Event',{style:({ position: 'absolute', top: '242px', right: '284px' }),attrs:{"size":_vm.size}}),_c('EventOverView',{style:({ position: 'absolute', top: '80px', right: '30px' }),attrs:{"size":_vm.size}}),_c('LiftMap',{attrs:{"size":_vm.size},on:{"changeMode":_vm.changeMode}})]:_vm._e(),(_vm.size === 'large')?[_c('div',{style:({
          position: 'relative',
        })},[_c('div',{style:({
            width: '1663px',
            height: '1080px',
            position: 'relative',
          })},[_c('Header',{style:({
              paddingLeft: '40px',
              paddingRight: '40px',
            })}),_c('Modal2',{style:({
              position: 'absolute',
              top: '162px',
              left: '1815px',
            }),attrs:{"size":_vm.size,"title":_vm.$store.state.modalContent.title,"code":_vm.$store.state.modalContent.code,"event":_vm.$store.state.modalContent.event,"time":_vm.$store.state.modalContent.time},model:{value:(_vm.$store.state.modalShow),callback:function ($$v) {_vm.$set(_vm.$store.state, "modalShow", $$v)},expression:"$store.state.modalShow"}}),_c('Modal',{style:({
              position: 'absolute',
              top: '162px',
              left: '1815px',
            }),attrs:{"size":_vm.size,"title":(_vm.alarmArr[0] || {}).liftName,"code":(_vm.alarmArr[0] || {}).liftCode,"event":(_vm.alarmArr[0] || {}).faultContent,"time":(_vm.alarmArr[0] || {}).faultTime},model:{value:(_vm.modalShow1),callback:function ($$v) {_vm.modalShow1=$$v},expression:"modalShow1"}}),_c('Modal',{style:({
              position: 'absolute',
              top: '142px', // -12
              left: '1887px', // +72
            }),attrs:{"size":_vm.size,"title":(_vm.alarmArr[1] || {}).liftName,"code":(_vm.alarmArr[1] || {}).liftCode,"event":(_vm.alarmArr[1] || {}).faultContent,"time":(_vm.alarmArr[1] || {}).faultTime},model:{value:(_vm.modalShow2),callback:function ($$v) {_vm.modalShow2=$$v},expression:"modalShow2"}}),_c('Modal',{style:({
              position: 'absolute',
              top: '122px', //
              left: '1959px', //
            }),attrs:{"size":_vm.size,"title":(_vm.alarmArr[2] || {}).liftName,"code":(_vm.alarmArr[2] || {}).liftCode,"event":(_vm.alarmArr[2] || {}).faultContent,"time":(_vm.alarmArr[2] || {}).faultTime},model:{value:(_vm.modalShow3),callback:function ($$v) {_vm.modalShow3=$$v},expression:"modalShow3"}}),_c('Modal',{style:({
              position: 'absolute',
              top: '102px', //
              left: '2031px', //
            }),attrs:{"size":_vm.size,"title":(_vm.alarmArr[3] || {}).liftName,"code":(_vm.alarmArr[3] || {}).liftCode,"event":(_vm.alarmArr[3] || {}).faultContent,"time":(_vm.alarmArr[3] || {}).faultTime},model:{value:(_vm.modalShow4),callback:function ($$v) {_vm.modalShow4=$$v},expression:"modalShow4"}}),_c('Modal',{style:({
              position: 'absolute',
              top: '82px', //
              left: '2103px', //
            }),attrs:{"size":_vm.size,"title":(_vm.alarmArr[4] || {}).liftName,"code":(_vm.alarmArr[4] || {}).liftCode,"event":(_vm.alarmArr[4] || {}).faultContent,"time":(_vm.alarmArr[4] || {}).faultTime},model:{value:(_vm.modalShow5),callback:function ($$v) {_vm.modalShow5=$$v},expression:"modalShow5"}}),_c('Overview',{style:({
              position: 'absolute',
              top: '90px',
              left: '40px',
            }),attrs:{"width":"622px","height":"220px"}}),_c('OverviewBar',{style:({
              position: 'absolute',
              top: '90px',
              left: '682px',
            }),attrs:{"size":_vm.size}}),_c('RuningTime',{style:({
              position: 'absolute',
              top: '330px',
              left: '40px',
            }),attrs:{"size":_vm.size}}),_c('EfficiencyRanking',{style:({
              position: 'absolute',
              top: '330px',
              left: '574px',
            }),attrs:{"size":_vm.size}}),_c('EnergyConsumptionRanking',{style:({
              position: 'absolute',
              top: '330px',
              left: '1108px',
            }),attrs:{"size":_vm.size}}),_c('EnergyConsumptionTrend',{style:({ position: 'absolute', top: '570px', left: '40px' }),attrs:{"size":_vm.size}}),_c('GuzhangRate',{style:({ position: 'absolute', top: '570px', left: '561px' }),attrs:{"size":_vm.size}}),_c('EventTrend',{style:({ position: 'absolute', bottom: '30px', left: '40px' }),attrs:{"size":_vm.size}}),_c('EventOverView',{style:({ position: 'absolute', top: '570px', right: '40px' }),attrs:{"size":_vm.size}}),_c('Event',{style:({ position: 'absolute', bottom: '30px', right: '295px' }),attrs:{"size":_vm.size}}),_c('Top',{style:({ position: 'absolute', bottom: '30px', right: '40px' }),attrs:{"size":_vm.size}})],1),_c('LiftMap',{attrs:{"size":_vm.size},on:{"changeMode":_vm.changeMode}})],1)]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }