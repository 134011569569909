var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Box',{style:({
    padding: '20px 10px 20px 10px',
  }),attrs:{"title":"近期事件列表","width":"368px","height":"318px"}},[_c('template',{slot:"title-right"},[_c('FilterLabel',{attrs:{"data":['预警', ' 故障']},model:{value:(_vm.activeIndex),callback:function ($$v) {_vm.activeIndex=$$v},expression:"activeIndex"}})],1),(_vm.activeIndex === 1)?[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"stripe":"","data":_vm.tableData}},[_c('el-table-column',{attrs:{"width":"141","prop":"date","label":"时间"}}),_c('el-table-column',{attrs:{"width":"85","prop":"name","label":"故障名称"}}),_c('el-table-column',{attrs:{"width":"65","prop":"address","label":"电梯"}}),_c('el-table-column',{attrs:{"width":"52","prop":"address","label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.status)?_c('div',{style:({
              color: 'rgba(47, 191, 76, 1)',
            })},[_vm._v(" 已解除 ")]):_c('div',{style:({
              color: 'rgba(253, 60, 60, 1)',
            })},[_vm._v(" 未解除 ")])]}}],null,false,2856325663)})],1),_c('div',{staticClass:"d-flex jc-end ai-center"},[_c('el-pagination',{attrs:{"current-page":_vm.currentPage,"background":"","pager-count":5,"layout":"prev, pager, next","total":_vm.$store.state.faultLog.total},on:{"current-change":_vm.handleCurrentChange}})],1)]:_vm._e(),(_vm.activeIndex === 0)?[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"stripe":"","data":_vm.warnTableData}},[_c('el-table-column',{attrs:{"width":"141","prop":"date","label":"时间"}}),_c('el-table-column',{attrs:{"width":"85","prop":"name","label":"事件名称"}}),_c('el-table-column',{attrs:{"width":"65","prop":"address","label":"电梯"}}),_c('el-table-column',{attrs:{"width":"52","label":"查看"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
              color: '#1962ff',
              fontSize: 12,
              cursor: 'pointer',
            }),on:{"click":function($event){return _vm.openModal(scope)}}},[_vm._v(" 查看 ")])]}}],null,false,3254144196)})],1),_c('div',{staticClass:"d-flex jc-end ai-center"},[_c('el-pagination',{attrs:{"current-page":_vm.warnCurrentPage,"background":"","pager-count":5,"layout":"prev, pager, next","total":_vm.$store.state.warnLog.total},on:{"current-change":_vm.handleWarnCurrentChange}})],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }