<template>
  <Box title="事件管理" width="622px" height="142px">
    <div class="d-flex">
      <div
        v-for="item in innerData"
        :key="item.label"
        class="w d-flex flex-column jc-center ai-start"
      >
        <div class="value">{{ item.value }}</div>
        <div class="label">{{ item.label }}</div>
      </div>
    </div>
  </Box>
</template>

<script>
import Box from "../../common/box";
export default {
  name: "eventoverview",
  data() {
    return {
      isActive: true,
      data: [
        {
          label: "未解决故障数量",
          p: "notRemovedFaultCount",
        },
        {
          label: "近 30 天故障数量",
          p: "recentFaultCount",
        },
        {
          label: "今日预警事件",
          p: "todayWarningCount",
        },
        {
          label: "近 30 天预警数量",
          p: "recentWarningCount",
        },
      ],
    };
  },
  computed: {
    innerData() {
      return this.data.map((item) => {
        return {
          ...item,
          value: (this.$store.state.eventStatistics[item.p]|| 0).toLocaleString(),
        };
      });
    },
  },
  components: {
    Box,
  },
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.value {
  font-family: CenturyGothic-Bold;
  font-size: 30px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 700;
}
.label {
  font-family: PingFangSC-Medium;
  font-size: 12px;
  color: #abb6d2;
  letter-spacing: 0;
  font-weight: 500;
  margin-top: 6px;
}
.w {
  flex: 1;
  margin-top: 20px;
  padding: 0 12px;
}
</style>
