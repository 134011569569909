<template>
  <Box
    :style="{
      padding: '20px 10px 20px 10px',
    }"
    title="近期事件列表"
    width="368px"
    height="318px"
  >
    <template slot="title-right">
      <FilterLabel v-model="activeIndex" :data="['预警', ' 故障']" />
    </template>
    <template v-if="activeIndex === 1">
      <el-table stripe :data="tableData" style="width: 100%">
        <el-table-column width="141" prop="date" label="时间">
        </el-table-column>
        <el-table-column width="85" prop="name" label="故障名称">
        </el-table-column>
        <el-table-column width="65" prop="address" label="电梯">
        </el-table-column>
        <el-table-column width="52" prop="address" label="状态">
          <template slot-scope="scope">
            <div
              :style="{
                color: 'rgba(47, 191, 76, 1)',
              }"
              v-if="scope.row.status"
            >
              已解除
            </div>
            <div
              v-else
              :style="{
                color: 'rgba(253, 60, 60, 1)',
              }"
            >
              未解除
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="d-flex jc-end ai-center">
        <el-pagination
          :current-page="currentPage"
          @current-change="handleCurrentChange"
          background
          :pager-count="5"
          layout="prev, pager, next"
          :total="$store.state.faultLog.total"
        >
        </el-pagination>
      </div>
    </template>
    <template v-if="activeIndex === 0">
      <el-table stripe :data="warnTableData" style="width: 100%">
        <el-table-column width="141" prop="date" label="时间">
        </el-table-column>
        <el-table-column width="85" prop="name" label="事件名称">
        </el-table-column>
        <el-table-column width="65" prop="address" label="电梯">
        </el-table-column>
        <el-table-column width="52" label="查看">
          <template slot-scope="scope">
            <div
              @click="openModal(scope)"
              :style="{
                color: '#1962ff',
                fontSize: 12,
                cursor: 'pointer',
              }"
            >
              查看
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="d-flex jc-end ai-center">
        <el-pagination
          :current-page="warnCurrentPage"
          @current-change="handleWarnCurrentChange"
          background
          :pager-count="5"
          layout="prev, pager, next"
          :total="$store.state.warnLog.total"
        >
        </el-pagination>
      </div>
    </template>
  </Box>
</template>

<script>
import moment from "moment";
import Box from "../../common/box";
import FilterLabel from "../../common/filter";
export default {
  name: "event",
  data() {
    return {
      isActive: true,
      currentPage: 1,
      activeIndex: 0,
      warnCurrentPage: 1,
    };
  },
  computed: {
    tableData() {
      return (this.$store.state.faultLog.rows || []).map((item) => {
        return {
          date: moment(item.faultTime).format("YYYY/MM/DD HH:mm:ss"),
          name: item.faultContent || item.faultCode,
          address: item.liftName,
          status: item.removeStatus ? 1 : 0,
        };
      });
    },
    warnTableData() {
      return (this.$store.state.warnLog.rows || []).map((item) => {
        return {
          date: moment(item.createTime).format("YYYY/MM/DD HH:mm:ss"),
          name: item.faultName || item.faultCode,
          address: "-",
        };
      });
    },
  },
  components: {
    Box,
    FilterLabel,
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    openModal(scope) {
      console.log(scope.row)
      this.$store.dispatch("openModal", {
        title: "",
        code: "",
        event: scope.row.name,
        time: scope.row.date,
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.$store.dispatch("fetchFaultLog", {
        projectName: this.$store.state.projectInfo.projectName,
        pageIndex: val,
      });
      console.log(`当前页: ${val}`);
    },
    handleWarnCurrentChange(val) {
      this.$store.dispatch("fetchWarnLog", {
        projectName: this.$store.state.projectInfo.projectName,
        pageIndex: val,
      });
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin-top: 8px;
}
.p-color {
  background-image: linear-gradient(
    0deg,
    #1962ff 0%,
    rgba(77, 161, 255, 0.68) 100%
  );
  border-radius: 1px;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}
.y-label {
  font-family: PingFangSC-Regular;
  font-size: 10px;
  color: #8e9ab4;
  letter-spacing: 0;
  font-weight: 400;
}
.filter {
  background: #060913;
  border-radius: 4px;
  padding: 3px;
}
.filter-text {
  padding: 5px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #9ba2b3;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: 400;
  cursor: pointer;
}
.active {
  color: #ffffff;
  background: rgba(58, 65, 81, 0.3);
  border-radius: 4px;
}
</style>
