<template>
  <div
    id="index"
    ref="appRef"
    :style="{
      backgroundImage: 'url(' + bgUrl + ')',
      width: size === 'small' ? '1920px' : '5120px',
      height: '1080px',
    }"
  >
    <dv-loading v-if="$store.getters.loading">Loading...</dv-loading>
    <div v-else :style="{ position: 'relative' }">
      <template v-if="size === 'small'">
        <Header />
        <Modal2
          v-model="$store.state.modalShow"
          :size="size"
          :title="$store.state.modalContent.title"
          :code="$store.state.modalContent.code"
          :event="$store.state.modalContent.event"
          :time="$store.state.modalContent.time"
          :style="{
            position: 'absolute',
            top: '251px',
            left: '589px',
          }"
        />
        <Modal
          v-model="modalShow1"
          :size="size"
          :title="(alarmArr[0] || {}).liftName"
          :code="(alarmArr[0] || {}).liftCode"
          :event="(alarmArr[0] || {}).faultContent"
          :time="(alarmArr[0] || {}).faultTime"
          :style="{
            position: 'absolute',
            top: '251px',
            left: '589px',
          }"
        />
        <Modal
          v-model="modalShow2"
          :size="size"
          :title="(alarmArr[1] || {}).liftName"
          :code="(alarmArr[1] || {}).liftCode"
          :event="(alarmArr[1] || {}).faultContent"
          :time="(alarmArr[1] || {}).faultTime"
          :style="{
            position: 'absolute',
            top: '227px', // -24
            left: '630px', // +41
          }"
        />
        <Modal
          v-model="modalShow3"
          :size="size"
          :title="(alarmArr[2] || {}).liftName"
          :code="(alarmArr[2] || {}).liftCode"
          :event="(alarmArr[2] || {}).faultContent"
          :time="(alarmArr[2] || {}).faultTime"
          :style="{
            position: 'absolute',
            top: '203px', // -24
            left: '671px', // +41
          }"
        />

        <Modal
          v-model="modalShow4"
          :size="size"
          :title="(alarmArr[3] || {}).liftName"
          :code="(alarmArr[3] || {}).liftCode"
          :event="(alarmArr[3] || {}).faultContent"
          :time="(alarmArr[3] || {}).faultTime"
          :style="{
            position: 'absolute',
            top: '181px', // -24
            left: '712px', // +41
          }"
        />

        <Modal
          v-model="modalShow5"
          :size="size"
          :title="(alarmArr[4] || {}).liftName"
          :code="(alarmArr[4] || {}).liftCode"
          :event="(alarmArr[4] || {}).faultContent"
          :time="(alarmArr[4] || {}).faultTime"
          :style="{
            position: 'absolute',
            top: '157px', // -24
            left: '752px', // +41
          }"
        />

        <Overview
          :style="{
            position: 'absolute',
            top: '90px',
            left: '30px',
          }"
          width="490px"
          height="202px"
        />
        <OverviewBar
          :style="{
            position: 'absolute',
            top: '312px',
            left: '30px',
          }"
          :size="size"
        />
        <RuningTime
          :style="{
            position: 'absolute',
            top: '600px',
            left: '30px',
          }"
          :size="size"
        />
        />
        <EfficiencyRanking
          :style="{
            position: 'absolute',
            top: '840px',
            left: '30px',
          }"
          :size="size"
        />
        <!-- 电梯能耗排行 -->
        <EnergyConsumptionRanking
          :style="{
            position: 'absolute',
            top: '840px',
            left: '540px',
            zIndex: 10,
          }"
          :size="size"
        />
        <!-- 能耗趋势图 -->
        <EnergyConsumptionTrend
          :style="{ position: 'absolute', top: '840px', left: '979px' }"
          :size="size"
        />
        <GuzhangRate
          :style="{ position: 'absolute', top: '840px', right: '30px' }"
          :size="size"
        />
        <EventTrend
          :style="{ position: 'absolute', top: '580px', right: '30px' }"
          :size="size"
        />
        <Top
          :style="{ position: 'absolute', top: '242px', right: '30px' }"
          :size="size"
        />
        <Event
          :style="{ position: 'absolute', top: '242px', right: '284px' }"
          :size="size"
        />
        <EventOverView
          :style="{ position: 'absolute', top: '80px', right: '30px' }"
          :size="size"
        />
        <LiftMap @changeMode="changeMode" :size="size" />
      </template>
      <template v-if="size === 'large'">
        <div
          :style="{
            position: 'relative',
          }"
        >
          <div
            :style="{
              width: '1663px',
              height: '1080px',
              position: 'relative',
            }"
          >
            <Header
              :style="{
                paddingLeft: '40px',
                paddingRight: '40px',
              }"
            />
            <Modal2
              v-model="$store.state.modalShow"
              :size="size"
              :title="$store.state.modalContent.title"
              :code="$store.state.modalContent.code"
              :event="$store.state.modalContent.event"
              :time="$store.state.modalContent.time"
              :style="{
                position: 'absolute',
                top: '162px',
                left: '1815px',
              }"
            />
            <Modal
              v-model="modalShow1"
              :size="size"
              :title="(alarmArr[0] || {}).liftName"
              :code="(alarmArr[0] || {}).liftCode"
              :event="(alarmArr[0] || {}).faultContent"
              :time="(alarmArr[0] || {}).faultTime"
              :style="{
                position: 'absolute',
                top: '162px',
                left: '1815px',
              }"
            />
            <Modal
              v-model="modalShow2"
              :size="size"
              :title="(alarmArr[1] || {}).liftName"
              :code="(alarmArr[1] || {}).liftCode"
              :event="(alarmArr[1] || {}).faultContent"
              :time="(alarmArr[1] || {}).faultTime"
              :style="{
                position: 'absolute',
                top: '142px', // -12
                left: '1887px', // +72
              }"
            />
            <Modal
              v-model="modalShow3"
              :size="size"
              :title="(alarmArr[2] || {}).liftName"
              :code="(alarmArr[2] || {}).liftCode"
              :event="(alarmArr[2] || {}).faultContent"
              :time="(alarmArr[2] || {}).faultTime"
              :style="{
                position: 'absolute',
                top: '122px', //
                left: '1959px', //
              }"
            />

            <Modal
              v-model="modalShow4"
              :size="size"
              :title="(alarmArr[3] || {}).liftName"
              :code="(alarmArr[3] || {}).liftCode"
              :event="(alarmArr[3] || {}).faultContent"
              :time="(alarmArr[3] || {}).faultTime"
              :style="{
                position: 'absolute',
                top: '102px', //
                left: '2031px', //
              }"
            />

            <Modal
              v-model="modalShow5"
              :size="size"
              :title="(alarmArr[4] || {}).liftName"
              :code="(alarmArr[4] || {}).liftCode"
              :event="(alarmArr[4] || {}).faultContent"
              :time="(alarmArr[4] || {}).faultTime"
              :style="{
                position: 'absolute',
                top: '82px', //
                left: '2103px', //
              }"
            />
            <Overview
              :style="{
                position: 'absolute',
                top: '90px',
                left: '40px',
              }"
              width="622px"
              height="220px"
            />
            <OverviewBar
              :style="{
                position: 'absolute',
                top: '90px',
                left: '682px',
              }"
              :size="size"
            />
            <RuningTime
              :style="{
                position: 'absolute',
                top: '330px',
                left: '40px',
              }"
              :size="size"
            />
            <EfficiencyRanking
              :style="{
                position: 'absolute',
                top: '330px',
                left: '574px',
              }"
              :size="size"
            />
            <EnergyConsumptionRanking
              :style="{
                position: 'absolute',
                top: '330px',
                left: '1108px',
              }"
              :size="size"
            />
            <EnergyConsumptionTrend
              :style="{ position: 'absolute', top: '570px', left: '40px' }"
              :size="size"
            />
            <GuzhangRate
              :style="{ position: 'absolute', top: '570px', left: '561px' }"
              :size="size"
            />
            <EventTrend
              :style="{ position: 'absolute', bottom: '30px', left: '40px' }"
              :size="size"
            />
            <EventOverView
              :style="{ position: 'absolute', top: '570px', right: '40px' }"
              :size="size"
            />
            <Event
              :style="{ position: 'absolute', bottom: '30px', right: '295px' }"
              :size="size"
            />
            <Top
              :style="{ position: 'absolute', bottom: '30px', right: '40px' }"
              :size="size"
            />
          </div>
          <LiftMap @changeMode="changeMode" :size="size" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Overview from "../components/overview";
import OverviewBar from "../components/overviewbar";
import RuningTime from "../components/runingtime";
import EfficiencyRanking from "../components/efficiencyranking";
import EnergyConsumptionRanking from "../components/energyconsumptionranking";
import EnergyConsumptionTrend from "../components/energyconsumptiontrend";
import EventTrend from "../components/eventrend";
import Top from "../components/top";
import Event from "../components/event";
import EventOverView from "../components/eventoverview";
import Header from "../components/header";
import drawMixin from "../utils/drawMixin";
import LiftMap from "../components/liftmap";
import GuzhangRate from "../components/guzhangrate";
import Modal from "../common/modal";
import Modal2 from "../common/modal2";
import { mapState } from "vuex";

const bg1 = require("@/assets/bg.png");
const bg2 = require("@/assets/bg-2.png");
const bg3 = require("@/assets/bg-large.png");

export default {
  mixins: [drawMixin],
  data() {
    return {
      modalShow1: false,
      modalShow2: false,
      modalShow3: false,
      modalShow4: false,
      modalShow5: false,
      mode: "map",
      timer1: null,
      timer2: null,
      timer3: null,
      timer4: null,
      timer5: null,
      timer6: null,
      timer7: null,
      timer8: null,
      timer9: null,
      timer10: null,
      timer11: null,
    };
  },
  computed: {
    ...mapState(["alarm"]),
    alarmArr() {
      return this.alarm;
    },
    bgUrl() {
      if (this.$store.getters.loading) return bg2;
      return this.size === "large" ? bg3 : this.mode === "map" ? bg1 : bg2;
    },
  },
  components: {
    Overview,
    OverviewBar,
    RuningTime,
    EfficiencyRanking,
    EnergyConsumptionRanking,
    EnergyConsumptionTrend,
    EventTrend,
    Top,
    Event,
    EventOverView,
    Header,
    LiftMap,
    GuzhangRate,
    Modal,
    Modal2,
    // Tool,
  },
  mounted() {
    this.$store.dispatch("fetchProjectData");

    // // 电梯总览
    // setTimeout(() => {
    //   this.timer1 = setInterval(() => {
    //     this.$store.dispatch("fetchStatusStatistics", {
    //       projectName: this.$store.state.projectInfo.projectName,
    //     });
    //   }, 1000 * 60);
    // }, 1000 * 60);

    // // 电梯运行统计
    // setTimeout(() => {
    //   this.timer2 = setInterval(() => {
    //     this.$store.dispatch("fetchStatistics", {
    //       projectName: this.$store.state.projectInfo.projectName,
    //     });
    //   }, 1000 * 60);
    // }, 1000 * 60);

    // // 事件管理
    // setTimeout(() => {
    //   this.timer3 = setInterval(() => {
    //     this.$store.dispatch("fetchEventStatistics", {
    //       projectName: this.$store.state.projectInfo.projectName,
    //     });
    //   }, 1000 * 60);
    // }, 1000 * 60);

    // // top5
    // setTimeout(() => {
    //   this.timer4 = setInterval(() => {
    //     this.$store.dispatch("fetchRecentTop", {
    //       projectName: this.$store.state.projectInfo.projectName,
    //     });
    //   }, 1000 * 60);
    // }, 1000 * 60);

    // //  事件趋势
    // setTimeout(() => {
    //   this.timer5 = setInterval(() => {
    //     this.$store.dispatch("fetchEventLogYearPerMonth", {
    //       projectName: this.$store.state.projectInfo.projectName,
    //     });
    //   }, 1000 * 60 * 10);
    // }, 1000 * 60 * 10);

    // // 故障率
    // setTimeout(() => {
    //   this.timer6 = setInterval(() => {
    //     this.$store.dispatch("fetchFaultRate", {
    //       projectName: this.$store.state.projectInfo.projectName,
    //     });
    //   }, 1000 * 60 * 10);
    // }, 1000 * 60 * 10);

    // // RealData
    // setTimeout(() => {
    //   this.timer7 = setInterval(() => {
    //     this.$store.dispatch("fetchLiftRealData", {
    //       projectName: this.$store.state.projectInfo.projectName,
    //     });
    //   }, 1000 * 6);
    // }, 1000 * 6);

    // // 天气
    // setTimeout(() => {
    //   this.timer8 = setInterval(() => {
    //     this.$store.dispatch("fetchLiftRealData", {
    //       projectName: this.$store.state.projectInfo.projectName,
    //     });
    //   }, 1000 * 60);
    // }, 1000 * 60);

    // // Faultlog
    // setTimeout(() => {
    //   this.timer9 = setInterval(() => {
    //     this.$store.dispatch("fetchFaultLog", {
    //       projectName: this.$store.state.projectInfo.projectName,
    //       pageIndex: this.$store.state.faultPagination.pageIndex,
    //     });
    //     this.$store.dispatch("fetchFaultLogAlarm", {
    //       projectName: this.$store.state.projectInfo.projectName,
    //     });
    //   }, 5000);
    // }, 1000 * 6);

    // // warnlog
    // setTimeout(() => {
    //   this.timer10 = setInterval(() => {
    //     this.$store.dispatch("fetchWarnLog", {
    //       projectName: this.$store.state.projectInfo.projectName,
    //       pageIndex: this.$store.state.faultPagination.pageIndex,
    //     });
    //   }, 5000);
    // }, 1000 * 6);

    // //   预警趋势
    // setTimeout(() => {
    //   this.timer11 = setInterval(() => {
    //     this.$store.dispatch("fetchWarnLogYearPerMonth", {
    //       projectName: this.$store.state.projectInfo.projectName,
    //     });
    //   }, 1000 * 60 * 10);
    // }, 1000 * 60 * 10);
  },
  beforeDestroy() {
    if (this.timer1) {
      clearInterval(this.timer1);
    }
    if (this.timer2) {
      clearInterval(this.timer2);
    }
    if (this.timer3) {
      clearInterval(this.timer3);
    }
    if (this.timer4) {
      clearInterval(this.timer4);
    }
    if (this.timer5) {
      clearInterval(this.timer5);
    }
    if (this.timer6) {
      clearInterval(this.timer6);
    }
    if (this.timer7) {
      clearInterval(this.timer7);
    }
    if (this.timer8) {
      clearInterval(this.timer8);
    }
    if (this.timer9) {
      clearInterval(this.timer9);
    }

    if (this.timer10) {
      clearInterval(this.timer10);
    }
    if (this.timer11) {
      clearInterval(this.timer11);
    }
  },
  methods: {
    changeMode(mode) {
      this.mode = mode;
    },
  },
  watch: {
    alarmArr: {
      handler(newValue) {
        if (newValue[0] && !this.modalShow1) {
          this.modalShow1 = true;
        }
        if (newValue[1] && !this.modalShow2) {
          this.modalShow2 = true;
        }
        if (newValue[2] && !this.modalShow3) {
          this.modalShow3 = true;
        }
        if (newValue[3] && !this.modalShow4) {
          this.modalShow4 = true;
        }
        if (newValue[4] && !this.modalShow5) {
          this.modalShow5 = true;
        }
      },
      deep: true,
    },
    modalShow1: {
      handler(newValue) {
        if (!newValue) {
          this.$store.dispatch(
            "deleteAlarm",
            (this.alarmArr[0] || {}).eventCode
          );
        }
      },
    },
    modalShow2: {
      handler(newValue) {
        if (!newValue) {
          this.$store.dispatch(
            "deleteAlarm",
            (this.alarmArr[1] || {}).eventCode
          );
        }
      },
    },
    modalShow3: {
      handler(newValue) {
        if (!newValue) {
          this.$store.dispatch(
            "deleteAlarm",
            (this.alarmArr[2] || {}).eventCode
          );
        }
      },
    },
    modalShow4: {
      handler(newValue) {
        if (!newValue) {
          this.$store.dispatch(
            "deleteAlarm",
            (this.alarmArr[3] || {}).eventCode
          );
        }
      },
    },
    modalShow5: {
      handler(newValue) {
        if (!newValue) {
          setTimeout(() => {
            this.$store.dispatch(
              "deleteAlarm",
              (this.alarmArr[4] || {}).eventCode
            );
          }, 0);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/test.scss";
#index {
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  transition: background-image 0.2s ease;
  transform-origin: 0 0;
  left: 50%;
  top: 50%;
}
</style>
